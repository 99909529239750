import useStore from "./store";
import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import './App.css';

import SignIn from "./components/signin";
import Navbar from "./components/nav";
import NewSearch from "./components/newsearch";
import Searches from "./components/searches";
import Clients from "./components/clients";
import NotFound from "./components/notfound";
import Home from "./components/home";
import IndividualSearch from "./components/individualsearch";
import Properties from "./components/properties";
import FileUploadDropzone from "./components/upload";
import ApartmentUrlFileUploadDropzone from "./components/uploadApartmentUrl";
import AllFieldsFileUploadDropzone from "./components/uploadAllFields";
import { LicenseInfo } from '@mui/x-license-pro';
import Storage from "./components/storage/storage";
import Video from "./components/storage/vide";
import Folder from "./components/storage/folder";
import Document from "./components/storage/document"; 
import Image from "./components/storage/image";

LicenseInfo.setLicenseKey(process.env.REACT_APP_PUBLIC_MUI_LICENSE_KEY);
axios.defaults.headers.common["X-API-Key"] = process.env.REACT_APP_API_KEY;

function App() {
  const user = useStore((state) => state.user);

  useEffect(() => {}, [user]);

  return (
    <div className="w-full">
      <Router>
        <Routes>
          <Route path="/video" element={<Video />} />
          <Route path="/folder" element={<Folder />} />
          <Route path="/document" element={<Document />} /> 
          <Route path="/image" element={<Image />} /> 

          {!user ? (
            <Route
              path="*"
              element={
                <div className="flex flex-col justify-center items-center h-screen">
                  <span className="text-3xl font-bold">Sidekick</span>
                  <SignIn />
                </div>
              }
            />
          ) : (
            <Route
              path="*"
              element={
                <>
                  <Navbar />
                  <div className="flex-grow overflow-auto ">
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/search" element={<NewSearch />} />
                      <Route path="/search/:id" element={<IndividualSearch />} />
                      <Route path="/searches" element={<Searches />} />
                      <Route path="/clients" element={<Clients />} />
                      <Route path="/properties" element={<Properties />} />
                      <Route path="/admin" element={<FileUploadDropzone />} />
                      <Route path="/admin-apartment-url" element={<ApartmentUrlFileUploadDropzone />} />
                      <Route path="/update-property" element={<AllFieldsFileUploadDropzone />} />
                      <Route path="/file-storage" element={<Storage />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </div>
                </>
              }
            />
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
