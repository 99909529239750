// SignIn.js
import React, { useState } from "react";
import axios from "axios";
import useStore from "../store"; // Assuming userStore is in the same directory

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const setUser = useStore((state) => state.setUser);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/auth/signin",
        { email, password }
      );
      setUser(response.data);
    } catch (error) {
      console.error("Sign in failed:", error);
    }
  };

  return (
    <div className="flex flex-col bg-black p-4 rounded-xl w-1/4">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 items-center justify-center p-4 w-full"
      >
        <input
          type="string"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="px-3 py-2 border rounded-xl w-full"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="px-3 py-2 border rounded-xl w-full"
        />
        <div className="flex flex-col gap-2 w-full">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-xl w-full"
          >
            Sign In
          </button>
          {/* 
            <span className="text-gray-300 text-center">or</span>
          <button
            onClick={() => alert("Not implemented yet")}
            className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded w-full"
          >
            Google SSO
          </button>
          */}
        </div>
      </form>
    </div>
  );
};

export default SignIn;
