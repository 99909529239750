import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Snackbar,
  Drawer,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Tabs,
  Tab,
  CardActions,
  bottomNavigationActionClasses,
  IconButton,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import Link from "@mui/material/Link";
import useStore from "../../store";
import TabProperties from "../tabs/tabs";
import Modal from "@mui/material/Modal";
import axios from "axios";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LanguageIcon from "@mui/icons-material/Language";
import MapIcon from "@mui/icons-material/Map";
import VideocamIcon from "@mui/icons-material/Videocam";
import PhoneIcon from "@mui/icons-material/Phone";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SecurityIcon from "@mui/icons-material/Security";
import Switch from "@mui/material/Switch";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import Popover from "@mui/material/Popover";
import ProrationCalculator from "../prorationCalculator";
import CalculateIcon from "@mui/icons-material/Calculate";
import CircularProgress from "@mui/material/CircularProgress";
import StarsIcon from "@mui/icons-material/Stars";
import {
  Menu,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import {
  DataGridPro,
  GridActionsCellItem,
  GridRowModes,
} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

const AllProperties = ({
  properties,
  setFilteredProperties,
  paginationModel,
  setPaginationModel,
  totalRows,
  setSortModel,
  sortModel,
  fetchData,
  loading,
  refresh,
}) => {
  const [currentSelectedData, setCurrentSelectedData] = useState({});
  const [selectedCellData, setSelectedCellData] = useState(null);
  const [isPopupDoubleClickOpen, setIsDoubleClickPopupOpen] = useState(false);
  const [bedCount, setBedCount] = useState({
    studio: 0,
    one_bed: 0,
    two_bed: 0,
    three_bed: 0,
    four_plus_bed: 0,
  });
  const [specialData, setSpecialData] = useState({});
  const [width, setWidth] = useState(true);
  const [termLengthProp, setTermLengthProp] = useState();
  const [monthFeeProp, setMonthFeeProp] = useState();
  const [monthFeePropReferenceProp, setMonthFeeReferenceProp] = useState();
  const [rentConcessionProp, setRentConcessionProp] = useState();
  const [rentConcessionReferenceProp, setRentConcessionReferenceProp] =
    useState();
  const [hideTab, setHideTab] = useState(true);
  const [modalLoader, setModalLoader] = useState(false);
  const [isSightMap, setIsSightMap] = useState(false);
  const [currentRowData, setCurrentRowData] = useState({});
  const [toggle, setToggle] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentId, setCurrentId] = useState({});
  const [currentUnit, setCurrentUnit] = useState([]);
  const [notAvailable, setNotAvailable] = useState(false);
  const [tempUnit, setTempUnit] = useState([]);
  const user = useStore((state) => state.user);
  const [isExpanded, setIsExpanded] = useState([]);
  const [responses, setResponses] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCategory, setShowCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [emailSnackbarOpen, setEmailSnackbarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [propertyId, setPropertyId] = useState(0);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [currentRow, setCurrentRow] = useState({});
  const [data, setData] = useState("");

  const handleDataFromChild = (childData) => {
    setData(childData);
  };

  const handleVirtualTourLinkCopy = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        console.log("Virtual tour link copied to clipboard:", link);
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Failed to copy virtual tour link:", error);
      });
  };
  const renderCellContent = (params) => {
    const { field, value, row } = params;
    if (field === "category" && (value === null || value === undefined)) {
      return "-";
    }
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      return JSON.stringify(value, null, 2); // Pretty print JSON object
    }

    if (field === "name" && row?.floorplan_link) {
      return (
        <Link
          href={row?.floorplan_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {value}
        </Link>
      );
    }

    if (field.includes("email")) {
      const handleEmailClick = (event) => {
        event.stopPropagation();
        navigator.clipboard
          .writeText(value)
          .then(() => {
            console.log("Email copied to clipboard:", value);
            setEmailSnackbarOpen(true);
          })
          .catch((error) => {
            console.error("Failed to copy email:", error);
          });
      };

      return (
        <span href="#" onClick={handleEmailClick} style={{ color: '#1976d2', textDecoration: 'underline' }}>
          {value}
        </span>
      );
    }
    // if (field ==="virtual_tour"){
    //   return 'Virtual '
    // }
    if (field === "virtual_tour") {
      return (
        <div className="custom-select">
          <Select
            style={{ width: 100, color: "#989ca3" }}
            value={null}
            displayEmpty
          >
            <MenuItem value={null} disabled>
              Show Links
            </MenuItem>
            {row?.virtual_tour !== undefined && row?.virtual_tour !== null ? (
              row?.virtual_tour.map((tour) => (
                <MenuItem
                  className="line-clamp-1"
                  key={tour?.id}
                  value={tour?.link}
                  onClick={() => handleVirtualTourLinkCopy(tour?.link)}
                >
                  {tour?.name
                    ? tour?.name
                    : tour?.link?.length > 25
                    ? tour?.link?.slice(0, 25) + "..."
                    : tour?.link}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="">-</MenuItem>
            )}
          </Select>
        </div>
      );
    }
    // if (field === "virtual_tour_edit") {
    //   return (
    //     <div style={{ cursor: "pointer" }}>
    //       <EditIcon />
    //     </div>
    //   );
    // }

    if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    }
    if (typeof value === "string") {
      // Check for URL
      try {
        const url = new URL(value);
        if (url.protocol === "http:" || url.protocol === "https:") {
          return (
            <Link href={value} target="_blank" rel="noopener noreferrer">
              {value}
            </Link>
          );
        }
      } catch (error) {
        // Not a URL
      }
      // Check for email
      if (value.includes("@") && value.includes(".")) {
        return <Link href={`mailto:${value}`}>{value}</Link>;
      }
      // Check for phone numbers (basic validation)
      const phoneRegex = /^\d{10}$/;
      if (
        phoneRegex.test(value.replace(/[\s\-\(\)]/g, "")) &&
        value.replace(/[\s\-\(\)]/g, "").length === 10
      ) {
        return <Link href={`tel:${value}`}>{value}</Link>;
      }
    }
    return value;
  };

  const isEmpty = (val) => [undefined, null, ""].includes(val);

  function getType(value) {
    if (value === "percentage") {
      return "%";
    } else if (value === "dollar") {
      return "$";
    } else {
      return "";
    }
  }
  const EditSendField = (params) => {
    const { row } = params;
    let singleRowData = { ...row };
    const [sendClick, setSendClick] = useState(0);
    const [send, setSend] = useState(row?.send);
    const [sendType, setSendType] = useState(row?.send_type);

    const handleSendChange = (event) => {
      setSend(event?.target?.value);
    };

    const isHighlighted = selectedRows?.id === row?.id;

    const handleSendSave = () => {
      setSendClick(0);
      if (send === row?.send) return;

      singleRowData.send = send;
      setFilteredProperties((prev) => {
        const oldData = [...prev];
        const index = oldData.findIndex((x) => x?.id === params?.id);
        if (index > -1) {
          oldData[index] = {
            ...oldData[index],
            send: send,
          };
        }
        return oldData;
      });
      handleRowUpdate(singleRowData);
    };

    const handleSendTypeChange = (event) => {
      setSendType(event?.target?.value);
    };

    const handleSendTypeSave = () => {
      if (sendType === row?.send_type) return;
      setSendClick(0);
      singleRowData.send_type = sendType;
      setFilteredProperties((prev) => {
        const oldData = [...prev];
        const index = oldData.findIndex((x) => x?.id === params?.id);
        if (index > -1) {
          oldData[index] = {
            ...oldData[index],
            send_type: sendType,
          };
        }
        return oldData;
      });
      handleRowUpdate(singleRowData);
    };

    const handleSendEnter = (event) => {
      if (event.key === "Enter") {
        setSendClick(0);
        if (send === row?.send) return;

        singleRowData.send = send;
        setFilteredProperties((prev) => {
          const oldData = [...prev];
          const index = oldData.findIndex((x) => x?.id === params?.id);
          if (index > -1) {
            oldData[index] = {
              ...oldData[index],
              send: send,
            };
          }
          return oldData;
        });
        handleRowUpdate(singleRowData);
      }
    };

    const handleSendTypeEnter = (event) => {
      if (event.key === "Enter") {
        setSendClick(0);
        if (sendType === row.send_type) return;

        singleRowData.send_type = sendType;
        setFilteredProperties((prev) => {
          const oldData = [...prev];
          const index = oldData.findIndex((x) => x?.id === params?.id);
          if (index > -1) {
            oldData[index] = {
              ...oldData[index],
              send_type: sendType,
            };
          }
          return oldData;
        });
        handleRowUpdate(singleRowData);
      }
    };

    const type = getType(row.send_type);

    let valueDisplay = null;
    if (send > 0) {
      if (type === "$") {
        valueDisplay = `${type}${send}`;
      } else {
        valueDisplay = `${send}${type}`;
      }
    }
    return (
      <div
        className={`group-hover/row:bg-neutral-100  ${
          isHighlighted ? "bg-cyan-800" : ""
        }`}
        style={{ display: "flex", flexDirection: "column", width: "100%" }}
        onClick={() => setSendClick(sendClick + 1)}
      >
        {sendClick > 1 ? (
          <>
            {" "}
            <div>
              <input
                type="number"
                value={send || ""}
                style={{ width: "100%" }}
                className="focus:outline-none border-0 group-hover/row:bg-neutral-100 w-[130px]"
                onChange={handleSendChange}
                onBlur={handleSendSave}
                onKeyPress={handleSendEnter}
                placeholder="-"
              />
            </div>
            <div>
              <select
                className="focus:outline-none border-1 w-[130px]  group-hover/row:bg-neutral-100"
                style={{ width: "100%" }}
                value={sendType || "percentage"}
                onChange={handleSendTypeChange}
                onBlur={handleSendTypeSave}
                onKeyPress={handleSendTypeEnter}
              >
                <option value="percentage">%</option>
                <option value="dollar">$</option>
              </select>
            </div>
          </>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ height: "50px", paddingTop: "15px" }}>
              {" "}
              {valueDisplay}
            </span>
          </div>
        )}
      </div>
    );
  };
  const EditEscortField = (params) => {
    const { row } = params;
    let singleRowData = { ...row };

    const [escort, setEscort] = useState(row?.escort);
    const [escortType, setEscortType] = useState(row?.escort_type);
    const [yearClick, setYearClick] = useState(0);

    const handleEscortChange = (event) => {
      setEscort(event?.target?.value);
    };

    const isHighlighted = selectedRows?.id === row?.id;

    const handleEscortSave = () => {
      setYearClick(0);
      if (escort === row?.escort) return;

      singleRowData.escort = escort;
      setFilteredProperties((prev) => {
        const oldData = [...prev];
        const index = oldData.findIndex((x) => x?.id === params?.id);
        if (index > -1) {
          oldData[index] = {
            ...oldData[index],
            escort: escort,
          };
        }
        return oldData;
      });
      handleRowUpdate(singleRowData);
    };

    const handleEscortTypeChange = (event) => {
      setEscortType(event?.target?.value);
    };

    const handleEscortTypeSave = () => {
      setYearClick(0);
      if (escortType === row?.escort_type) return;

      singleRowData.escort_type = escortType;
      setFilteredProperties((prev) => {
        const oldData = [...prev];
        const index = oldData.findIndex((x) => x?.id === params?.id);
        if (index > -1) {
          oldData[index] = {
            ...oldData[index],
            escort_type: escortType,
          };
        }
        return oldData;
      });
      handleRowUpdate(singleRowData);
    };

    const handleEscortEnter = (event) => {
      if (event.key === "Enter") {
        setYearClick(0);
        if (escort === row?.escort) return;

        singleRowData.escort = escort;
        setFilteredProperties((prev) => {
          const oldData = [...prev];
          const index = oldData.findIndex((x) => x?.id === params?.id);
          if (index > -1) {
            oldData[index] = {
              ...oldData[index],
              escort: escort,
            };
          }
          return oldData;
        });
        handleRowUpdate(singleRowData);
      }
    };

    const handleEscortTypeEnter = (event) => {
      if (event.key === "Enter") {
        setYearClick(0);
        if (escortType === row.escort_type) return;

        singleRowData.escort_type = escortType;
        setFilteredProperties((prev) => {
          const oldData = [...prev];
          const index = oldData.findIndex((x) => x?.id === params?.id);
          if (index > -1) {
            oldData[index] = {
              ...oldData[index],
              escort_type: escortType,
            };
          }
          return oldData;
        });
        handleRowUpdate(singleRowData);
      }
    };

    const type = getType(row.escort_type);
    let valueDisplay = null;
    if (escort > 0) {
      if (type === "$") {
        valueDisplay = `${type}${escort}`;
      } else {
        valueDisplay = `${escort}${type}`;
      }
    }
    return (
      <div
        className={`group-hover/row:bg-neutral-100  ${
          isHighlighted ? "bg-cyan-800" : ""
        }`}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: 2,
        }}
        onClick={() => setYearClick(yearClick + 1)}
      >
        {yearClick > 1 ? (
          <>
            {" "}
            <div>
              <input
                type="number"
                value={escort || ""}
                className="focus:outline-none border-0 group-hover/row:bg-neutral-100 w-[130px]"
                onChange={handleEscortChange}
                onBlur={handleEscortSave}
                onKeyPress={handleEscortEnter}
                placeholder="-"
              />
            </div>
            <div>
              <select
                className="focus:outline-none border-1 w-[130px] group-hover/row:bg-neutral-100"
                value={escortType || "percentage"}
                onChange={handleEscortTypeChange}
                onBlur={handleEscortTypeSave}
                onKeyPress={handleEscortTypeEnter}
              >
                <option value="percentage">%</option>
                <option value="dollar">$</option>
              </select>
            </div>
          </>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ height: "50px", paddingTop: "15px" }}>
              {valueDisplay}
            </span>
          </div>
        )}
      </div>
    );
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editId, setEditId] = useState();

  const handleEditIconClick = (id) => {
    setEditId(id);
    setIsPopupOpen(true);
  };
  const [tours, setTours] = useState([]);

  useEffect(() => {
    const filteredProperties = properties.filter(
      (property) => property?.id === editId
    );
    const mappedTours = filteredProperties
      .map((property) => property?.virtual_tour)
      .flat();

    if (mappedTours?.length > 0 && mappedTours[0] !== null) {
      setTours(mappedTours);
    } else {
      setTours([{ name: "", link: "" }]);
    }
  }, [properties, editId, currentId]);

  const handleChange = (propertyIndex, field, value) => {
    const updatedTours = [...tours];
    updatedTours[propertyIndex][field] = value;
    setTours(updatedTours);
  };

  const handleRemove = (propertyIndex) => {
    const updatedTours = [...tours];
    updatedTours.splice(propertyIndex, 1);
    setTours(updatedTours);
  };

  const handleAdd = () => {
    setTours([...tours, { name: "", link: "" }]);
  };
  const handleClosePopup = () => {
    setTours([{ name: "", link: "" }]);
    setIsPopupOpen(false);
  };
  const handleSavePopup = () => {
    const updatedIndex = properties.findIndex(
      (property) => property.id === editId
    );
    const filteredVirtualFields = tours.filter((field) => field?.link);
    if (updatedIndex >= 0) {
      const updatedProperty = {
        ...properties[updatedIndex],
        virtual_tour: filteredVirtualFields,
      };

      handleRowUpdate(updatedProperty);
    }

    setIsPopupOpen(false);
  };

  const renderActionButtons = (params) => {
    // You can access the row data using params.row
    // Add any button or action component you need here
    return (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          handleActionOne(params.row);
        }}
        variant="contained"
        color="primary"
        size="small"
      >
        *
      </Button>
    );
  };
  const handleActionOne = (rowData) => {
    //console.log((((rowData.send == null || rowData.send == "" || rowData.send == 0) && (rowData.bonus == null || rowData.bonus == "" || rowData.bonus == 0) && (rowData.escort >0) || ((rowData.send == 0 || rowData.send == null || rowData.send == "") && (rowData.escort != 0 || rowData.escort != null || rowData.escort != "") && (rowData.bonus != 0 || rowData.bonus != null || rowData.bonus != ""))) ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>" : ""))
    // Define HTML content with inline styles
    const emailContent = `
      <div style="font-size: 16px; font-family: 'Calibri', sans-serif;">
        <p style="font-weight: 700; margin: 0; padding: 0"><a href="${
          rowData?.website
        }">${rowData?.name ? rowData?.name : " "}</a>${(((((rowData.send == null || rowData.send == "" || rowData.send == 0) && (rowData.bonus == null || rowData.bonus == "" || rowData.bonus == 0) && (rowData.escort >0)) || ((rowData.send == 0 || rowData.send == null || rowData.send == "") && (rowData.escort != 0 && rowData.escort != null && rowData.escort != "") && (rowData.bonus != 0 && rowData.bonus != null && rowData.bonus != ""))) || (((((rowData.send_type == "percentage" && rowData.escort_type == "percentage") || (rowData.send_type == "percentage" && rowData.escort_type == null) || (rowData.send_type == null && rowData.escort_type == "percentage") || (rowData.send_type == null && rowData.escort_type == null)) && (rowData.escort > rowData.send)) || ((rowData.send_type == "dollar" && rowData.escort_type == "dollar" && rowData.escort > rowData.send)))) ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>" : "") ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>" : "")}</p>
        <p style="margin: 0; padding: 0">Price: $ <span style="font-weight: 700;">${
          rowData?.special ?? ""
        }</span></p>
        <p style="margin: 0; padding: 0">Size: sq ft 1/1</p>
        <p style="margin: 0; padding: 0">Unit: #</p>
        <p style="margin: 0; padding: 0">Available: - ${
          rowData?.hold_time ? rowData?.hold_time : ""
        }</p>
        <p style="margin: 0; padding: 0">Location: ${
          rowData?.submarket ? rowData?.submarket : ""
        }</p>
        <p style="margin: 0; padding: 0">${
          rowData?.last_line ? rowData?.last_line : ""
        }</p>
      </div>
    `;

    const plainTextContent = `${
      rowData?.name ? `${rowData.name} (${rowData.website}) ${(((((rowData.send == null || rowData.send == "" || rowData.send == 0) && (rowData.bonus == null || rowData.bonus == "" || rowData.bonus == 0) && (rowData.escort >0)) || ((rowData.send == 0 || rowData.send == null || rowData.send == "") && (rowData.escort != 0 && rowData.escort != null && rowData.escort != "") && (rowData.bonus != 0 && rowData.bonus != null && rowData.bonus != ""))) || (((((rowData.send_type == "percentage" && rowData.escort_type == "percentage") || (rowData.send_type == "percentage" && rowData.escort_type == null) || (rowData.send_type == null && rowData.escort_type == "percentage") || (rowData.send_type == null && rowData.escort_type == null)) && (rowData.escort > rowData.send)) || ((rowData.send_type == "dollar" && rowData.escort_type == "dollar" && rowData.escort > rowData.send)))) ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>" : "") ? "*if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit." : "")}` : ""
    }
Price: $ ${rowData?.special ?? ""}
Size: sq ft 1/1
Unit: #
Available: - ${rowData?.hold_time ? rowData?.hold_time : ""}
Location: ${rowData?.submarket ? rowData?.submarket : ""}
${rowData?.last_line ? rowData?.last_line : ""}`;

    navigator.clipboard
      .write([
        new ClipboardItem({
          "text/html": new Blob([emailContent], { type: "text/html" }),
          "text/plain": new Blob([plainTextContent], { type: "text/plain" }),
        }),
      ])
      .then(() => {
        console.log("Email info copied to clipboard!");
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.error("Failed to copy email info: ", err);
      });
  };
  const handleActionOneUnit = (rowData, unit, type) => {
    setSnackbarOpen(true);
    let units;
    let sqft;
    if (type == "main") {
      units = unit.units;
      sqft = unit.square_feet;
    } else {
      units = unit.split("_")[0];
      sqft = unit.split("_")[1];
    }
    // Define HTML content with inline styles
    const emailContent = `
      <div style="font-size: 16px; font-family: 'Calibri', sans-serif;">
        <p style="font-weight: 700; margin: 0; padding: 0"><a href="${
          rowData?.website
        }">${rowData?.name ? rowData?.name : " "}</a>${(((((rowData.send == null || rowData.send == "" || rowData.send == 0) && (rowData.bonus == null || rowData.bonus == "" || rowData.bonus == 0) && (rowData.escort >0)) || ((rowData.send == 0 || rowData.send == null || rowData.send == "") && (rowData.escort != 0 && rowData.escort != null && rowData.escort != "") && (rowData.bonus != 0 && rowData.bonus != null && rowData.bonus != ""))) || (((((rowData.send_type == "percentage" && rowData.escort_type == "percentage") || (rowData.send_type == "percentage" && rowData.escort_type == null) || (rowData.send_type == null && rowData.escort_type == "percentage") || (rowData.send_type == null && rowData.escort_type == null)) && (rowData.escort > rowData.send)) || ((rowData.send_type == "dollar" && rowData.escort_type == "dollar" && rowData.escort > rowData.send)))) ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>" : "") ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>" : "")}</p>
        <p style="margin: 0; padding: 0">Price: $ <span style="font-weight: 700;">${
          rowData?.special ? rowData?.special : ""
        }</span></p>
        <p style="margin: 0; padding: 0">Size: sq ft 1/1</p>${sqft}
        <p style="margin: 0; padding: 0">Unit: #</p>${units}
        <p style="margin: 0; padding: 0">Available: - ${
          rowData?.hold_time ? rowData?.hold_time : ""
        }</p>
        <p style="margin: 0; padding: 0">Location: ${
          rowData?.submarket ? rowData?.submarket : ""
        }</p>
        <p style="margin: 0; padding: 0">${
          rowData?.last_line ? rowData?.last_line : ""
        }</p>
      </div>
    `;

    const plainTextContent = `${
      rowData?.name ? `${rowData.name} (${rowData.website}) ${(((((rowData.send == null || rowData.send == "" || rowData.send == 0) && (rowData.bonus == null || rowData.bonus == "" || rowData.bonus == 0) && (rowData.escort >0)) || ((rowData.send == 0 || rowData.send == null || rowData.send == "") && (rowData.escort != 0 && rowData.escort != null && rowData.escort != "") && (rowData.bonus != 0 && rowData.bonus != null && rowData.bonus != ""))) || (((((rowData.send_type == "percentage" && rowData.escort_type == "percentage") || (rowData.send_type == "percentage" && rowData.escort_type == null) || (rowData.send_type == null && rowData.escort_type == "percentage") || (rowData.send_type == null && rowData.escort_type == null)) && (rowData.escort > rowData.send)) || ((rowData.send_type == "dollar" && rowData.escort_type == "dollar" && rowData.escort > rowData.send)))) ? "<b> *if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit.</b>" : "") ? "*if you're interested in touring this property, please let me know, I have to go in person with you to tour in order to receive credit." : "")}` : ""
    }
Price: $ ${rowData?.special ? rowData?.special : ""}
Size: ${sqft} sq ft 1/1
Unit: ${units}
Available: - ${rowData?.hold_time ? rowData?.hold_time : ""}
Location: ${rowData?.submarket ? rowData?.submarket : ""}
${rowData?.last_line ? rowData?.last_line : ""}`;

    navigator.clipboard
      .write([
        new ClipboardItem({
          "text/html": new Blob([emailContent], { type: "text/html" }),
          "text/plain": new Blob([plainTextContent], { type: "text/plain" }),
        }),
      ])
      .then(() => {
        console.log("Email info copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy email info: ", err);
      });
  };
  const customWidthColumns = [
    "state",
    "id",
    "zip_code",
    "market",
    "studio_price",
    "one_bed_price",
    "two_bed_price",
    "three_bed_price",
    "four_plus_bed_price",
    "admin_fee",
    "application_fee",
    "number_floors",
    "single_family",
    "townhome",
    "send",
    "escort",
    "bonus",
    "year_built",
    "year_renovated",
    "high_rise",
    "studio",
    "one_bed",
    "two_bed",
    "three_bed",
    "four_plus_bed",
    "yard",
    "garage",
    "loft",
    "study",
    "desk",
    "ev_charger",
    "fireplace",
    "no_carpet",
    "tub_shower",
    "industrial",
    "concrete_floors",
    "furnished",
    "high_ceilings",
    "terrace",
    "roof_terrace",
    "floor_to_ceiling_windows",
    "concierge",
    "blacklisted",
    "one_and_a_half_bath",
  ];
  // Function to generate columns based on the order in defaultVisibleColumns
  const generateColumns = () => {
    let orderedColumns = [];
    orderedColumns.push(
      {
        field: "actions",
        headerName: "Copy",
        width: 100,
        renderCell: renderActionButtons,
      },
      {
        field: "id",
        headerName: "Id",
        editable: true,
        width: 100,
      },
      {
        field: "name",
        headerName: "Name",
        editable: true,
        width: 150,
        renderCell: renderCellContent,
      },
      {
        field: "category",
        headerName: "Category",
        editable: true,
        width: 150,
        renderCell: renderCellContent,
        renderEditCell: (params) => (
          <Select
            className="w-full "
            value={params.value || ""}
            onChange={(e) =>
            {
              handleApplyCategory(e.target.value);
              handleRowUpdate(currentSelectedData?.row, {}, currentSelectedData?.field, e.target.value)
              params.api.setEditCellValue({ ...params, value: e.target.value })
            }
            }
          >
            <MenuItem value={"null"}>-</MenuItem>
            <MenuItem
              value={"Not working with locators"}
            >
              Not working with locators
            </MenuItem>
            <MenuItem  value={"Student Housing"}>
            Student Housing
            </MenuItem>
            <MenuItem  value={"Affordable Housing"}>
            Affordable Housing
            </MenuItem>
            <MenuItem  value={"Do not add"}>
            Do not add
            </MenuItem>
            <MenuItem  value={"Unsure"}>
              Unsure
            </MenuItem>
          </Select>
        ),
      },
      {
        field: "virtual_tour",
        headerName: "Virtual Tour",
        width: 70,
        sortable: false,
        renderCell: renderCellContent,
      },
      {
        field: "virtual_tour_edit",
        type: "actions",
        width: 20,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon style={{ color: "#0075FF" }} />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditIconClick(id)}
              color="inherit"
            />,
          ];
        },
      }
    );

    if (properties.length > 0) {
      Object.keys(properties[0]).forEach((key) => {
        if (
          key !== "category" &&
          key !== "id" &&
          key !== "name" &&
          key !== "escort_type" &&
          key !== "send_type" &&
          key !== "phone" &&
          key !== "virtual_tour" &&
          key !== "number_units"
        ) {
          const isSpecialWidthColumn = customWidthColumns.includes(key);

          let columnConfig = {
            field: key,
            headerName:
              key == "special_toggle"
                ? ""
                : key.charAt(0).toUpperCase() +
                  key?.slice(1).replace(/_/g, " "),
            editable: true,
            width:
              key == "special_toggle" || key == "year_built_renovated" ? 70 : isSpecialWidthColumn ? 100 : key == "submarket" ? 100 : 150,
            renderCell:
              key === "send"
                ? EditSendField
                : key === "escort"
                ? EditEscortField
                : renderCellContent,
          };
          if (key === "building_class") {
            columnConfig.renderEditCell = (params) => (
              <Select
                className="w-full "
                value={params.value || ""}
                onChange={(e) =>
                  params.api.setEditCellValue({
                    ...params,
                    value: e.target.value,
                  })
                }
              >
                {" "}
                <MenuItem value={null}>-</MenuItem>
                <MenuItem value="Single Family">Single Family</MenuItem>
                <MenuItem value="Townhome">Townhome</MenuItem>
                <MenuItem value="High Rise">High Rise</MenuItem>
              </Select>
            );
          }
          columnConfig.editable = key !== "send" && key !== "escort";
          if (
            key === "bonus" ||
            key === "is_sight_map" ||
            key === "special_toggle" ||
            key === "admin_fee" ||
            key === "application_fee" ||
            key === "studio_price" ||
            key === "one_bed_price" ||
            key === "two_bed_price" ||
            key === "three_bed_price" ||
            key === "four_plus_bed_price"
          ) {
            if (key == "special_toggle") {
              columnConfig.renderCell = (params) => (
                <Button title="Calculated Special">
                  <CalculateIcon
                    onClick={(e) => {
                      setWidth(true);
                      if (!specialData?.term_length_studio)
                        setSpecialData(params.row);
                      setCurrentId(params.row);
                      setTermLengthProp(params.row.term_length);
                      setMonthFeeProp(params.row.months_fee);
                      setMonthFeeReferenceProp(params.row.months_fee_reference);
                      setRentConcessionProp(params.row.rent_concession);
                      setRentConcessionReferenceProp(
                        params.row.rent_concession_reference
                      );
                      setIsDropdownOpen(true);
                    }}
                  />
                </Button>
              );
            } else if (key === "is_sight_map") return false;
            else
              columnConfig.renderCell = (params) => (
                <span>
                  {!isEmpty(params?.value) ? `$${params?.value}` : ""}
                </span>
              );
          }
          // if (key === "send" || key === "escort") {
          //   columnConfig.renderCell = (params) => {
          //     const type = getType(params.row[`${key}_type`]);

          //     let valueDisplay;
          //     if (type === "$") {
          //       valueDisplay = `${type}${params?.value}`;
          //     } else {
          //       valueDisplay = `${params?.value}${type}`;
          //     }

          //     return (
          //       <span>{!isEmpty(params?.value) ? valueDisplay  : ""}</span>
          //     );
          //   };
          // }

          if (key === "one_and_a_half_bath") {
            columnConfig = {
              field: key,
              headerName: "1.5 Bath",
              editable: true,
              width: 100,
              renderCell: renderCellContent,
            };
          }
          orderedColumns.push(columnConfig);
        }
      });
    }

    // orderedColumns.push({
    //   field: "special_toggle123",
    //   headerName: "",
    //   width: 100,
    //   renderCell: renderToggleButton,
    // });

    orderedColumns.push({
      field: "blacklist",
      headerName: "Blacklist",
      width: 100,
      renderCell: renderBlacklistButton,
    });

    orderedColumns.push({
      field: "star",
      headerName: "",
      width: 10,
      sortable: false,
      renderCell: renderStartButton,
      disableClickEventBubbling: true,
    });

    orderedColumns.push({
      field: "apartment_url1",
      headerName: "View Units",
      width: 100,
      renderCell: renderViewDetailsButton,
      disableClickEventBubbling: true,
    });

    return orderedColumns;
  };

  const handleRowUpdate = async (newRow, oldRow, isGarageOrYard, category) => {
    delete newRow.units;
    delete newRow.models;
    delete newRow.move_in_special;
    if(category != null && category !== "")
      newRow.category = category;
    if (!user || user.role !== "admin") {
      console.log("User is not authorized to update rows.");
    } else {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/property/${newRow.id}`,
          { data: newRow, isYard: isGarageOrYard === "yard_unit" ? true : false, isGarage: isGarageOrYard === "garage_unit" ? true : false }
        );
        setFilteredProperties((prev) => {
          const oldData = [...prev];
          const index = oldData.findIndex((x) => x?.id === newRow?.id);
          if (index > -1) {
            oldData[index] = newRow;
          }
          return oldData;
        });
        await fetchData();
        return response?.data;
      } catch (error) {
        console.error("Error updating row:", error);
        throw new Error("Could not update row. Please try again.");
      }
    }
  };
  const handleMultiRowUpdate = async (updatedRows) => {
    if (!user || user.role !== "admin") {
      console.log("User is not authorized to update rows.");
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/property/batchUpdate`,
          { rows: updatedRows }
        );
        await fetchData();
        setFilteredProperties((prev) => {
          const oldData = [...prev];
          updatedRows.forEach((newRow) => {
            const index = oldData.findIndex((x) => x?.id === newRow?.id);
            if (index > -1) {
              oldData[index] = newRow;
            }
          });
          return oldData;
        });

        return response.data;
      } catch (error) {
        console.error("Error updating rows:", error);
        throw new Error("Could not update rows. Please try again.");
      }
    }
  };

  const renderBlacklistButton = (params) => {
    return (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          handleActionTwo(params.row);
          toggleDrawer(true);
        }}
        variant="contained"
        color="primary"
        size="small"
      >
        Blacklist
      </Button>
    );
  };

  const renderToggleButton = (params) => {
    return (
      <Button title="Calculated Special">
        <CalculateIcon
          onClick={(e) => {
            setCurrentId(params.row);
            setTermLengthProp(params.row.term_length);
            setMonthFeeProp(params.row.months_fee);
            setMonthFeeReferenceProp(params.row.months_fee_reference);
            setRentConcessionProp(params.row.rent_concession);
            setRentConcessionReferenceProp(
              params.row.rent_concession_reference
            );
            setIsDropdownOpen(true);
          }}
        />
      </Button>
      // <Switch title="Calculated Special" defaultChecked={params.row.special_toggle} onChange={(e) => {
      //   setCurrentId(params.row);
      //   if (e.target.checked) {
      //     setTermLengthProp(params.row.term_length);
      //     setMonthFeeProp(params.row.months_fee);
      //     setMonthFeeReferenceProp(params.row.months_fee_reference);
      //     setRentConcessionProp(params.row.rent_concession);
      //     setRentConcessionReferenceProp(params.row.rent_concession_reference);
      //     setIsDropdownOpen(true);
      //   }
      //   else
      //   {
      //     //const handleSubmit = async (submit, leaseTerm, monthsFree, rentConcession, monthsFreeReference, rentConcessionReference, bool) => {
      //       handleSubmit(params.row, "","","","","", false)
      //   }
      // }} />
    );
  };

  const renderStartButton = (params) => {
    if (params.row.apartment_url?.includes("sightmap")) {
      return <StarsIcon style={{ color: "blue" }} />;
    }
  };

  const renderViewDetailsButton = (params) => {
    if (params.row.apartment_url != null && params.row.apartment_url != "") {
      return (
        <Button variant="contained" onClick={toggleDrawer(true, params.row)}>
          View
        </Button>
      );
    }
  };

  const loadSwitch = () => {
    setHideTab(false);
    setCurrentRow([]);
    setCurrentUnit([]);
    viewDetails(currentRowData, true, true);
  };

  const viewDetails = async (rowData, open, refresh) => {
    setIsSightMap(rowData?.is_sight_map);
    try {
      if (rowData?.id) {
        setPropertyId(rowData?.id);
        const response = await axios
          .post(`${process.env.REACT_APP_API_URL}/view-units/${rowData?.id}`)
          .then((res) => {
            let unitValue = properties.find((x) => x.id == rowData?.id);
            setTempUnit(res.data);
            if (notAvailable) setCurrentUnit(res.data);
            else {
              if (rowData?.is_sight_map) {
                setCurrentUnit(res?.data?.filter((x) => x.archived == false));
              } else {
                setCurrentUnit(
                  res.data.filter(
                    (x) =>
                      !x?.unit_details?.includes("Not Available") &&
                      x.archived == false
                  )
                );
              }
            }
            setCurrentRow(properties.find((x) => x.id == rowData?.id));
            let bed = properties.find((x) => x.id == rowData?.id);
            if (properties.find((x) => x.id == rowData?.id))
              unitValue["units"] = res.data;
            if (bed != null) {
              setBedCount({
                studio: unitValue.units.filter((x) => x.unit_studio == true)
                  .length,
                one_bed: unitValue.units.filter((x) => x.unit_bedroom == 1)
                  .length,
                two_bed: unitValue.units.filter((x) => x.unit_bedroom == 2)
                  .length,
                three_bed: unitValue.units.filter((x) => x.unit_bedroom == 3)
                  .length,
                four_plus_bed: unitValue.units.filter(
                  (x) => x.unit_bedroom >= 4
                ).length,
              });
            }
            if (bed != null) {
              let val =
                properties
                  .find((x) => x.id == rowData?.id)
                  .units.filter((x) => x.unit_studio == true).length > 0
                  ? 0
                  : properties
                      .find((x) => x.id == rowData?.id)
                      .units.filter((x) => x.unit_bedroom == 1).length > 0
                  ? 1
                  : properties
                      .find((x) => x.id == rowData?.id)
                      .units.filter((x) => x.unit_bedroom == 2).length > 0
                  ? 2
                  : properties
                      .find((x) => x.id == rowData?.id)
                      .units.filter((x) => x.unit_bedroom == 3).length > 0
                  ? 3
                  : 4;
              if (!refresh) setCurrentIndex(val);
            }
            setHideTab(true);
          });
      }
      setIsDrawerOpen(open);
    } catch (error) {
      console.error("Error updating row:", error);
      throw new Error("Could not update row. Please try again.");
    }
  };

  const handleActionTwo = async (rowData) => {
    setCurrentRow(rowData);
    if (!user || user.role !== "admin") {
    } else {
      try {
        // Make an API call to update the backend
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/property/${rowData.id}`,
          { blacklist: !rowData.blacklist }
        );
        return response.data;
      } catch (error) {
        console.error("Error updating row:", error);
        throw new Error("Could not update row. Please try again.");
      }
    }
  };

  const reduceWidth = (cancel) => {
    setWidth(cancel);
  };

  const handleClose = () => {
    setContextMenu(null);
    setShowCategory(null);
  };
  const handleCategoryChange = (category) => {
    setShowCategory(category);
    setSubMenuOpen(true);
  };
  const handleApplyCategory = (category) => {
    setSelectedCategory(category);
    handleClose();
  };
  const handleSubMenuClose = () => {
    setSubMenuOpen(false);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };
  useEffect(() => {
    if (selectedCategory) {
      let updatedCategory = selectedCategory;
      if (selectedCategory === "null") {
        updatedCategory = null;
      }
      const updatedRows = selectedRows.map((row) => ({
        ...row,
        category: updatedCategory,
      }));
      setSelectedRows(updatedRows);
      if (updatedRows.length === 1) {
        const singleRow = updatedRows[0];
        handleRowUpdate(singleRow);
      } else {
        handleMultiRowUpdate(updatedRows);
      }
      setSelectedCategory("");
    }
  }, [selectedCategory]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  const handleCloseEmailSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setEmailSnackbarOpen(false);
  };
  const generateCsvName = () => {
    const date = new Date();
    const formattedDate = `${
      date.getMonth() + 1
    }_${date.getDate()}_${date.getFullYear()}`;
    return `sidekick_${formattedDate}.csv`;
  };
  const CustomToolbar = ({ apiRef }) => {
    const handleExport = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/export-csv`,
          {
            responseType: "blob",
          }
        );

        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", generateCsvName());
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error("Error downloading the CSV file:", error);
      }
    };
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />

        <Button color="primary" onClick={handleExport} startIcon={<SaveIcon />}>
          Export
        </Button>
      </GridToolbarContainer>
    );
  };
  const toggleDrawer = (open, params) => (event) => {
    setIsSightMap(params?.is_sight_map);
    setCurrentRowData(params);
    viewDetails(params, open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (!open) {
      setCurrentRowData({});
      setTabIndex(0);
      setPropertyDetails({});
      setIsDrawerOpen(open);
      setNotAvailable(false);
    }
  };
  const handleTabChange = async (event, newIndex) => {
    if (event.target.innerText == "STUDIO") setCurrentIndex(0);
    if (event.target.innerText == "1 BED") setCurrentIndex(1);
    if (event.target.innerText == "2 BED") setCurrentIndex(2);
    if (event.target.innerText == "3 BED") setCurrentIndex(3);
    if (event.target.innerText == "4 BED") setCurrentIndex(4);
    setTabIndex(newIndex);
    //viewDetails(currentRow);
  };
  const handleCellClick = (params, event) => {
    if (params.field === "apartment_url1") {
      event.stopPropagation(); // Prevent row selection for Column 1
    }
  };
  const openTabVirtualTour = async (e) => {
    if (e.length > 0) {
      let delay = 2000;
      e.forEach((url, index) => {
        setTimeout(() => {
          window.open(url.link, "_blank");
        }, index * delay);
      });
    }
  };
  const copyUnit = (e, type) => {
    handleActionOneUnit(currentRow, e, type);
  };
  const toggleExpand = (index) => {
    const newExpandState = [...isExpanded];
    newExpandState[index] = !newExpandState[index];
    setIsExpanded(newExpandState);
  };

  const handleSubmit = async (
    submit,
    leaseTerm,
    monthsFree,
    rentConcession,
    monthsFreeReference,
    rentConcessionReference,
    bool
  ) => {
    try {
      setSpecialData({});
      setSpecialData((prev) => ({
        ...prev,
        leaseTerm: leaseTerm,

        monthsFree: monthsFree,

        monthsFreeReference: monthsFreeReference,

        rentConcession: rentConcession,

        rentConcessionReference: rentConcessionReference,
      }));
      if (currentId?.id > 0 || submit?.id > 0) {
        // if(currentId?.id)
        // {
        //   currentId.special_toggle = !currentId?.special_toggle;
        //   // currentId.special_calculation_price = parseFloat(convertedTax);
        //   // currentId.special_calculation_text = currentId?.special?.replace("special= $", `special= $${convertedTax}`);
        // }
        setModalLoader(true);
        const response = await axios
          .put(
            `${process.env.REACT_APP_API_URL}/property-unit/${
              currentId?.id ?? submit?.id
            }`,
            {
              leaseTerm,
              monthsFree,
              rentConcession,
              monthsFreeReference,
              rentConcessionReference,
              specialToggle: bool,
            }
          )
          .then((res) => {
            refresh(2000);
            setModalLoader(false);
            setIsDropdownOpen(false);
          });
        // viewDetails(setCurrentIndex, true);
        // setIsDropdownOpen(false);
        return response?.data;
      }
    } catch (error) {
      console.error("Error updating row:", error);
      throw new Error("Could not update row. Please try again.");
    }
  };

  const handleCancel = (cancel) => {
    //refresh(2000);
    setIsDropdownOpen(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width ? 400 : 1000,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleCellDoubleClick = (params) => {
    setCurrentSelectedData(params);
    if (
      params?.field !== "category" &&
      params?.field !== "virtual_tour_edit" &&
      params?.field !== "virtual_tour" &&
      params?.field !== "send" &&
      params?.field !== "escort"
    ) {
      if(typeof params.value != "boolean")
      setSelectedCellData(params.value);
      if(typeof params.value == "boolean")
        setSelectedCellData(params.value ? "Yes" : "No");
      setIsDoubleClickPopupOpen(true);
    }
  };

  const handleCloseDoubleClick = () => {
    setIsDoubleClickPopupOpen(false);
  };
  return (
    <div className=" mx-auto py-4 bg-white rounded-lg">
      <Modal
        open={isDropdownOpen}
        BackdropProps={{
          onClick: (event) => handleCancel(),
        }}
        onClose={() => {
          setIsDropdownOpen(false);
          // setFilteredProperties((prev) => {
          //   const oldData = [...prev];
          //   const index = oldData.findIndex((x) => x?.id === currentId?.id);
          //   let newRow = oldData.find((x) => x?.id === currentId?.id);
          //   newRow.special_toggle = false;
          //   if (index > -1) {
          //     oldData[index] = newRow;
          //   }
          //   return oldData;
          // });
          // fetchData();
          setCurrentId({});
          handleCancel();
          setWidth(true);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {modalLoader && (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress disableShrink />
            </Box>
          )}
          {!modalLoader && (
            <ProrationCalculator
              monthFee={monthFeeProp}
              monthFeeReference={monthFeePropReferenceProp}
              rentConcessions={rentConcessionProp}
              rentConcessionReferences={rentConcessionReferenceProp}
              termLength={termLengthProp}
              isSpecial={true}
              specialData={specialData}
              submit={handleSubmit}
              cancel={handleCancel}
              reduceWidth={reduceWidth}
            />
          )}
        </Box>
      </Modal>
      <div>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{ "& .MuiDrawer-paper": { width: "80%", boxShadow: "unset" } }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "10px",
            }}
          >
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div>
            <div>
              <div
                style={{
                  marginTop: "20px",
                  color: "#4e5c76",
                  fontSize: "40px",
                  marginLeft: "30px",
                  marginRight: "30px",
                  fontWeight: "800",
                }}
              >
                {currentRow?.name}
              </div>
              <div>
                <div
                  class="grid grid-cols-4 gap-4"
                  style={{
                    paddingBottom: "20px",
                    paddingRight: "30px",
                    paddingLeft: "30px",
                  }}
                >
                  {currentRow && currentRow.street_address && (
                    <div>
                      <LocationOnIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp;
                      {currentRow.street_address}
                    </div>
                  )}
                  {currentRow && currentRow.website && (
                    <div>
                      <LanguageIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp;
                      <a
                        href={currentRow.website}
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                      >
                        Website
                      </a>
                    </div>
                  )}
                  {currentRow && currentRow.google_maps_url && (
                    <div>
                      <MapIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp;
                      <a
                        href={currentRow.google_maps_url}
                        target="_blank"
                        style={{ textDecoration: "underline" }}
                      >
                        Map
                      </a>
                    </div>
                  )}
                  {currentRow && currentRow.virtual_tour && (
                    <div>
                      <VideocamIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp;
                      <span
                        onClick={() =>
                          openTabVirtualTour(currentRow.virtual_tour)
                        }
                        style={{ textDecoration: "underline" }}
                      >
                        Virtual Tour
                      </span>
                    </div>
                  )}
                  {currentRow && currentRow.phone && (
                    <div>
                      <PhoneIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp;
                      <span style={{ textDecoration: "underline" }}>
                        {currentRow.phone}
                      </span>
                    </div>
                  )}
                  {currentRow && currentRow.email && (
                    <div>
                      <MarkunreadIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp;
                      <span style={{ textDecoration: "underline" }}>
                        {currentRow.email}
                      </span>
                    </div>
                  )}
                  {currentRow && currentRow.year_built && (
                    <div>
                      <CalendarMonthIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp; Build Year: &nbsp;
                      {currentRow.year_built}
                    </div>
                  )}
                  {currentRow && currentRow.year_renovated && (
                    <div>
                      <CalendarMonthIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp; Renovated Year: &nbsp;
                      {currentRow.year_renovated}
                    </div>
                  )}
                  {currentRow && currentRow.escort && (
                    <div>
                      <SecurityIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp; Escort %: &nbsp;{currentRow.escort}
                    </div>
                  )}
                  {currentRow && currentRow.send && (
                    <div>
                      <SecurityIcon style={{ color: "#4e5c76" }} />
                      &nbsp;&nbsp; Send %: &nbsp;{currentRow.send}
                    </div>
                  )}
                </div>
              </div>
              {data && (
                <div
                  style={{
                    margin: "30px",
                    height: "10vh",
                    display: "flex",
                    alignItems: "center",
                    background: "#f5fbfc",
                    border: ".0625rem dashed #0f8ac0",
                  }}
                >
                  <div style={{ marginLeft: "20px", display: "flex" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <LocalOfferIcon style={{ color: "#4e5c76" }} />
                    </div>
                    &nbsp;&nbsp;
                    <div>
                      <div style={{ fontSize: "20px" }}>Move-in Special</div>
                      <div style={{ fontSize: "15px" }}>{data}</div>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Switch
                  onChange={() => {
                    if (!notAvailable) setCurrentUnit(tempUnit);
                    else {
                      if (isSightMap) {
                        setCurrentUnit(
                          tempUnit?.filter((x) => x.archived == false)
                        );
                      } else {
                        setCurrentUnit(
                          tempUnit?.filter(
                            (x) =>
                              !x?.unit_details?.includes("Not Available") &&
                              x.archived == false
                          )
                        );
                      }
                    }
                    setNotAvailable(!notAvailable);
                  }}
                />
                <label style={{ marginRight: "30px", fontWeight: 800 }}>
                  View Unavailable Units
                </label>
              </div>
              {!hideTab && (
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress disableShrink />
                </Box>
              )}
              {hideTab && (
                <>
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                    sx={{
                      backgroundColor: "white",
                      color: "#4e5c76",
                      "& .MuiTab-root": {
                        backgroundColor: "#f8f8f8",
                        color: "#4e5c76",
                        "&.Mui-selected": {
                          backgroundColor: "#4e5c76",
                          color: "white",
                        },
                      },
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    {bedCount.studio > 0 && (
                      <Tab
                        label="STUDIO"
                        style={{ borderTopLeftRadius: "20px" }}
                      />
                    )}
                    {bedCount.one_bed > 0 && (
                      <Tab
                        label="1 BED"
                        style={{
                          borderTopLeftRadius:
                            bedCount.studio == 0 ? "20px" : "",
                          borderTopRightRadius:
                            bedCount.two_bed == 0 &&
                            bedCount.three_bed == 0 &&
                            bedCount.four_plus_bed == 0
                              ? "20px"
                              : "",
                        }}
                      />
                    )}
                    {bedCount.two_bed > 0 && (
                      <Tab
                        label="2 BED"
                        style={{
                          borderTopLeftRadius:
                            bedCount.studio == 0 && bedCount.one_bed == 0
                              ? "20px"
                              : "",
                          borderTopRightRadius:
                            bedCount.three_bed == 0 &&
                            bedCount.four_plus_bed == 0
                              ? "20px"
                              : "",
                        }}
                      />
                    )}
                    {bedCount.three_bed > 0 && (
                      <Tab
                        label="3 bed"
                        style={{
                          borderTopLeftRadius:
                            bedCount.studio == 0 &&
                            bedCount.one_bed == 0 &&
                            bedCount.two_bed == 0
                              ? "20px"
                              : "",
                          borderTopRightRadius:
                            bedCount.four_plus_bed == 0 ? "20px" : "",
                        }}
                      />
                    )}
                    {bedCount.four_plus_bed > 0 && (
                      <Tab
                        label="4 bed"
                        style={{ borderTopRightRadius: "20px" }}
                      />
                    )}
                  </Tabs>
                  <TabPanel value={tabIndex} index={0}>
                    <TabProperties
                      propertyDetails={currentRow}
                      currentRow={currentRow}
                      current={currentIndex}
                      currentUnit={currentUnit}
                      onData={handleDataFromChild}
                      loadSwitch={loadSwitch}
                    />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={1}>
                    <TabProperties
                      propertyDetails={currentRow}
                      currentRow={currentRow}
                      current={currentIndex}
                      currentUnit={currentUnit}
                      onData={handleDataFromChild}
                      loadSwitch={loadSwitch}
                    />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={2}>
                    <TabProperties
                      propertyDetails={currentRow}
                      currentRow={currentRow}
                      current={currentIndex}
                      currentUnit={currentUnit}
                      onData={handleDataFromChild}
                      loadSwitch={loadSwitch}
                    />
                  </TabPanel>

                  <TabPanel value={tabIndex} index={3}>
                    <TabProperties
                      propertyDetails={currentRow}
                      currentRow={currentRow}
                      current={currentIndex}
                      currentUnit={currentUnit}
                      onData={handleDataFromChild}
                      loadSwitch={loadSwitch}
                    />
                  </TabPanel>
                  <TabPanel value={tabIndex} index={4}>
                    <TabProperties
                      propertyDetails={currentRow}
                      currentRow={currentRow}
                      current={currentIndex}
                      currentUnit={currentUnit}
                      onData={handleDataFromChild}
                      loadSwitch={loadSwitch}
                    />
                  </TabPanel>
                </>
              )}
            </div>
          </div>
        </Drawer>
      </div>
      <Box
        sx={{ height: "80vh", width: "100%" }}
        onContextMenu={handleContextMenu}
        style={{ cursor: "context-menu" }}
      >
        {properties.length > 0 && (
          <DataGridPro
            initialState={{
              pinnedColumns: {
                left: [
                  "__check__",
                  "actions",
                  "id",
                  "name",
                  "star",
                  "apartment_url1",
                ],
              },
            }}
            columnVisibilityModel={{
              months_fee_reference: false,
              rent_concession_reference: false,
              term_length: false,
              months_fee: false,
              rent_concession: false,
              application_fee: false,
              admin_fee: false,
            }}
            onCellDoubleClick={handleCellDoubleClick}
            rows={properties.map((property, index) => ({
              id: property.id || index,
              ...property,
            }))}
            columns={generateColumns()}
            pageSize={paginationModel.pageSize}
            checkboxSelection
            components={{
              Toolbar: CustomToolbar,
            }}
            onCellClick={handleCellClick}
            onRowSelectionModelChange={(newSelection) => {
              const selectedRowsData = properties.filter((row) =>
                newSelection.includes(row.id)
              );
              setSelectedRows(selectedRowsData);
            }}
            //processRowUpdate={handleRowUpdate}
            experimentalFeatures={{ newEditingApi: true }}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={(e) => {
              setPaginationModel((prev) => {
                if (e.pageSize != prev?.pageSize) {
                  return {
                    page: 0,
                    pageSize: e.pageSize,
                  };
                }
                return e;
              });
            }}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            rowCount={totalRows}
            loading={loading}
            getRowClassName={(params) => {
              const currentRowIndex =
                properties.length > 0
                  ? properties.findIndex((row) => row.id === params.id)
                  : 0;
              return currentRowIndex % 2 === 0 ? "even-row" : "odd-row";
            }}
            {...properties}
          />
        )}
        <div className="custom-menuitem">
          {selectedRows.length > 0 && (
            <Menu
              open={contextMenu !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
            >
              <MenuItem
                style={{
                  height: 31,
                }}
                onClick={() => handleCategoryChange("Change Category")}
              >
                Change Category
              </MenuItem>

              {subMenuOpen &&
                showCategory !== null &&
                showCategory === "Change Category" && (
                  <Menu
                    open={subMenuOpen}
                    onClose={handleSubMenuClose}
                    anchorReference="anchorPosition"
                    anchorPosition={{
                      top: contextMenu.mouseY,
                      left: contextMenu.mouseX + 160,
                    }}
                  >
                    <MenuItem onClick={() => handleApplyCategory("null")}>
                      -
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleApplyCategory("Not working with locators")
                      }
                    >
                      Not working with locators
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleApplyCategory("Student Housing")}
                    >
                      Student Housing
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleApplyCategory("Affordable Housing")}
                    >
                      Affordable Housing
                    </MenuItem>
                    <MenuItem onClick={() => handleApplyCategory("Do not add")}>
                      Do not add
                    </MenuItem>
                    <MenuItem onClick={() => handleApplyCategory("Unsure")}>
                      Unsure
                    </MenuItem>
                  </Menu>
                )}
            </Menu>
          )}
        </div>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000000}
        style={{ right: 0, top: "65px" }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseSnackbar}
        message="Copied to clipboard!"
        id="testing"
      />
      <Snackbar
        open={emailSnackbarOpen}
        autoHideDuration={1000000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        style={{ right: 0, top: "65px" }}
        onClose={handleCloseEmailSnackbar}
        message="Copied to clipboard!"
      />
      {isPopupOpen && (
        <div className="popup">
          <Dialog open={isPopupOpen} onClose={handleClosePopup}>
            <DialogTitle className="flex flex-row justify-between">
              <>Edit Virtual Tour</>
              <IconButton onClick={handleAdd}>
                <AddIcon style={{ color: "#0075FF" }} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div>
                {tours.map((tour, propertyIndex) => (
                  <div
                    key={propertyIndex}
                    className="flex gap-10 "
                    style={{ margin: 16 }}
                  >
                    <TextField
                      name={`tours.${propertyIndex}.name`}
                      label={`Virtual Tour Name ${propertyIndex + 1}`}
                      value={tour?.name}
                      onChange={(e) =>
                        handleChange(
                          propertyIndex,
                          "name",
                          e.target.value.replace(/^\s+/, "")
                        )
                      }
                    />
                    <TextField
                      name={`tours.${propertyIndex}.link`}
                      label={`Virtual Tour Link ${propertyIndex + 1}`}
                      value={tour?.link}
                      onChange={(e) =>
                        handleChange(
                          propertyIndex,
                          "link",
                          e.target.value.replace(/^\s+/, "")
                        )
                      }
                    />

                    <IconButton onClick={() => handleRemove(propertyIndex)}>
                      <DeleteIcon style={{ color: "red" }} />
                    </IconButton>
                  </div>
                ))}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePopup}>Cancel</Button>
              <Button
                onClick={handleSavePopup}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      <Modal
        open={isPopupDoubleClickOpen}
        onClose={handleCloseDoubleClick}
        aria-labelledby="popup-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            padding: 4,
            borderRadius: 1,
          }}
        >
          <Typography id="popup-title" variant="h6" component="h2" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
            Edit - {currentSelectedData?.row?.name} - {currentSelectedData?.colDef?.headerName}
          </Typography>
          <Typography variant="body1">
            <textarea rows={10} cols={80} onChange={(e) => {
              setSelectedCellData(e.target.value);
              setCurrentSelectedData((prevState) => ({
                ...prevState,
                row: {
                  ...prevState.row,
                  [currentSelectedData?.field]: e.target.value,
                },
              }));
            }}>
              {selectedCellData}
            </textarea>
          </Typography>
          <div
            style={{ display: "flex", justifyContent: "end", width: "100%" }}
          >
            <Button variant="contained" color="primary" onClick={() => {
              handleRowUpdate(currentSelectedData?.row, {}, currentSelectedData?.field)
              setIsDoubleClickPopupOpen(false);
            }}>
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
export default AllProperties;
