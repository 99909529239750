import React, { useState, useEffect } from "react";
import axios from "axios";
import folderImage from "../../images/macFolder.png";
import fileIcon from "../../images/fileIcon.png";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FolderIcon from "@mui/icons-material/Folder";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Preview } from "@mui/icons-material";
import { Button, TextField, Snackbar } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Navigate, useLocation } from "react-router-dom";
let bool = true;
const Input = styled("input")({
  display: "none",
});
const ITEM_HEIGHT = 48;
const Folder = ({}) => {
  const query = useQuery(); 
  const [currentFormat, setCurrentFormat] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [showRename, setShowRename] = useState(false);
  const [currentFolder, setCurrentFolder] = useState({});
  const [createNewFolderName, setCreateNewFolderName] = useState("");
  const [file, setFile] = useState(null);
  const [routeArray, setRouteArray] = useState(decodeURIComponent(atob(query.get("folderUrl"))).replace(/\/{2,}/g, "/").slice(0, -1).split("/").map((x) => x + "/"));
  const [disableFolder, setDisableFolder] = useState(decodeURIComponent(atob(query.get("folderUrl"))).slice(0, -1).split("/").map((x) => x + "/").length);
  const [listFolderAndFile, setListFolderAndFile] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorAddEl, setAnchorAddEl] = React.useState(null);
  const [isLoader, setIsLoader] = React.useState(false);
  const [modalThumbnailFileOpen, setOpenModalThumbnailFile] =
    React.useState(false);
  const [showDownload, setShowDownload] = React.useState(true);
  const [showThumbnail, setShowThumbnail] = React.useState(true);
  const fileTypes = ["JPEG", "PNG", "GIF", "PDF", "DOCX", "TXT"];
  const navigation = useNavigate();
  const testParam = decodeURIComponent(atob(query.get("folderUrl")));
  // const handleChange = (file) => {
  //   setFile(file);
  // };

  // const handleFolderChange = (event) => {
  //   const files = event.target.files;
  //   const filesArray = Array.from(files);
  //   setSelectedFiles(filesArray);
  // };

  const uploadBatch = async (filesBatch) => {
    const formData = new FormData();
    filesBatch.forEach((file) => {
      formData.append('files', file, file.webkitRelativePath || file.name);
    });

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/uploadFolder`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }); 
    } catch (error) {
      console.error('Error uploading batch:', error);
      throw error;
    }
  };
  const open = Boolean(anchorEl);
  const openAdd = Boolean(anchorAddEl);
  const handleClick = (event, data) => {
    setCurrentFolder(data);
    if (data?.type === "folder") {
      setShowThumbnail(true);
      setShowDownload(true);
      setOptions((prev) => ["Share"]);
    } else if (data?.type === "video") {
      setShowThumbnail(false);
      setShowDownload(false);
      setOptions((prev) => [
        "Share",
        "Download",
      ]);
    } else if (data?.type === "document") {
      setShowThumbnail(true);
      setShowDownload(false);
      setOptions((prev) => ["Share", "Download"]);
    } else if (data?.type === "image") {
      setShowThumbnail(true);
      setShowDownload(false);
      setOptions((prev) => ["Download"]);
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const deleteFolder = async () => {
    try {
      const response = await axios
        .put(`${process.env.REACT_APP_API_URL}/deleteFolder`, {
          folderName: currentFolder,
          path: routeArray,
        })
        .then((response) => {
          setAnchorEl(null);
          listAll();
        });

      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const downloadFile = async () => {
    try {
      if (currentFolder?.type == "image") {
        // const response = await fetch(currentFolder.downloadUrl);
        // const blob = await response.blob();
        // const url = window.URL.createObjectURL(blob);
        // const link = document.createElement("a");
        // link.href = url;
        // link.download = currentFolder.name;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(url);
        const link = document.createElement("a");
        link.href = currentFolder.downloadUrl;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
      } else {
        const response = await fetch(currentFolder.downloadUrl);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = currentFolder.name;
        document.body.appendChild(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(blobUrl);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const addThumbnail = async () => {
    setOpenModalThumbnailFile(true);
  };

  const renameFile = async () => {
    try {
      const result = currentFolder.name.replace(/\/$/, "");
      let temp = result;
      let temp1 = temp.split(".");
      temp1.splice(temp1.length - 1, 1);
      let temp2 = temp1.join(",");
      let payloadFile = temp2;
      if (currentFolder.type != "folder")
        setCurrentFormat(temp.split(".")[temp.split(".").length - 1]);
      else setCurrentFormat("");
      setCreateNewFolderName(payloadFile);
      setShowRename(true);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const shareFolder = async () => {
    try {
      let utfUrl =
        currentFolder.type == "folder"
          ? window.location.origin +
            "/folder?folderUrl=" +
            btoa(
              routeArray.join("/")?.replace(/\/{2,}/g, "/") +
                encodeURIComponent(currentFolder?.name) +
                "/"
            )
          : currentFolder.type == "document" || currentFolder.type == "image"
          ? window.location.origin +
          "/" + currentFolder.type +"?documentUrl=" +
          btoa(
            routeArray.join("/")?.replace(/\/{2,}/g, "/") +
              encodeURIComponent(currentFolder?.name)
          )
          : window.location.origin +
            "/video?videoUrl=" +
            btoa(
              routeArray.join("/")?.replace(/\/{2,}/g, "/") +
                encodeURIComponent(currentFolder?.name)
            );


      const plainTextContent = utfUrl;

      navigator.clipboard
        .write([
          new ClipboardItem({
            "text/plain": new Blob([plainTextContent], { type: "text/plain" }),
          }),
        ])
        .then(() => {
          console.log("Email info copied to clipboard!");
          setSnackbarOpen(true);
        })
        .catch((err) => {
          console.error("Failed to copy email info: ", err);
        });
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return; // Prevents the snackbar from closing when the user clicks outside of it
    }
    setSnackbarOpen(false); // Close the Snackbar
  };

  const listAll = async () => {
    try {
      setIsLoader(true);
      let routeTemp = routeArray?.join("/")?.replace("//", "/");
      if (!routeTemp?.endsWith("/") && routeTemp != "") {
        routeTemp = routeTemp + "/";
      }
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/listAll`, {
          folderPath: bool ? testParam : routeTemp,
        })
        .then(async (res) => {
          bool = false;
          for (let item of res.data?.items) {
            if (item.type == "video") {
              const blobUrl = await createBlobUrl(item.downloadUrl);
              break;
            }
          }
          setIsLoader(false);
          setListFolderAndFile([]);
          setListFolderAndFile(res?.data?.items || []);
          console.log("File uploaded successfully:", res.data);
        });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    listAll();
  }, [routeArray]);

  const createBlobUrl = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error creating Blob URL:", error);
      return null;
    }
  };

  return (
    <div>
      <div className="flex" style={{ flexWrap: "wrap" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            {routeArray.length > 0 && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <button onClick={() => setRouteArray([])} disabled={true}>
                  <FolderIcon style={{ color: "#8f8f8f", fontSize: "40px" }} />
                </button>
              </div>
            )}
            {routeArray?.map((x, index) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <KeyboardDoubleArrowRightIcon
                  style={{ color: "#8f8f8f", fontSize: "30px" }}
                />
                <button
                disabled={index < disableFolder - 1}
                  style={{ fontSize: "20px" }}
                  onClick={(e) => {
                    setRouteArray((prevArray) => {
                      const updatedArray = [...prevArray];
                      updatedArray?.splice(index + 1, updatedArray.length);
                      return updatedArray;
                    });
                  }}
                >
                  {x?.replace(/\//g, "")}
                </button>
              </div>
            ))}
          </div>
          {/* <div style={{ padding: "10px" }}> 
            <Button
              value="Upload Folder"
              onClick={handleAddClose}
              variant="contained"
            >
              Upload Folder
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <Button
              value="Add Folder"
              onClick={handleAddClose}
              variant="contained"
            >
              Add Folder
            </Button>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              value="Upload File"
              onClick={handleAddClose}
              variant="contained"
            >
              Upload File
            </Button> 
          </div> */}
        </div>
        {listFolderAndFile &&
          listFolderAndFile?.length > 0 &&
          listFolderAndFile.map((x) => (
            <div>
              {x?.type == "folder" && x?.name != "" && (
                <div
                  className="flex justify-center items-center flex-col"
                  style={{
                    margin: "10px",
                    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => {
                        handleClick(e, x);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      marginBottom: "-7px",
                    }}
                  >
                    <button
                      folderType={x?.type}
                      folderName={x?.name}
                      onClick={(e) => {
                        if (
                          e?.currentTarget?.getAttribute("folderType") ==
                          "folder"
                        ) {
                          setRouteArray((prevArray) => [
                            ...prevArray,
                            e?.currentTarget?.getAttribute("folderName") + "/",
                          ]);
                        }
                      }}
                    >
                      <img
                        src={folderImage}
                        style={{ height: "100px", width: "130px" }}
                      />
                    </button>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {x?.name?.replace("/", "")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}

        {listFolderAndFile &&
          listFolderAndFile?.length > 0 &&
          listFolderAndFile.map((x) => (
            <div>
              {x?.type == "video" && x?.name != "" && (
                <div
                  className="flex justify-center items-center flex-col"
                  style={{
                    margin: "10px",
                    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => {
                        handleClick(e, x);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                  <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                    <video
                      controls
                      style={{ height: "100px", width: "130px" }}
                      poster={x?.thumbNail}
                    >
                      <source src={x?.downloadUrl} />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {x?.name?.replace("/", "")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}

        {listFolderAndFile &&
          listFolderAndFile?.length > 0 &&
          listFolderAndFile.map((x) => (
            <div>
              {x?.type == "image" &&
                x?.name != "" &&
                !x?.name?.includes("-thumb-") && (
                  <div
                    className="flex justify-center items-center flex-col"
                    style={{
                      margin: "10px",
                      boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                          handleClick(e, x);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </div>
                    <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                      <img
                        src={x?.downloadUrl}
                        style={{ height: "100px", width: "130px" }}
                      />
                    </div>
                    <div>
                      <div
                        style={{
                          width: "100px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {x?.name?.replace("/", "")}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          ))}

        {listFolderAndFile &&
          listFolderAndFile?.length > 0 &&
          listFolderAndFile.map((x) => (
            <div>
              {x?.type == "document" && x?.name != "" && (
                <div
                  className="flex justify-center items-center flex-col"
                  style={{
                    margin: "10px",
                    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => {
                        handleClick(e, x);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                  <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                    <img
                      src={fileIcon}
                      style={{ height: "100px", width: "130px" }}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {x?.name?.replace("/", "")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <>
            {
              <MenuItem
                key={option}
                value={option}
                onClick={(e) => {
                  setAnchorEl(null);
                  let key = e?.currentTarget?.getAttribute("value");
                  if (key === "Delete") {
                    deleteFolder();
                  } else if (key === "Share") {
                    shareFolder();
                  } else if (key === "Rename") {
                    renameFile();
                  } else if (key === "Download") {
                    downloadFile();
                  } else if (key === "Add Thumbnail") {
                    addThumbnail();
                  }
                }}
              >
                {option}
              </MenuItem>
            }
          </>
        ))}
      </Menu> 
      <Snackbar
        open={snackbarOpen}
        style={{ right: 0, top: "65px" }}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseSnackbar}
        message="Copied to clipboard!"
      />
      <Modal
        open={isLoader}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Modal>
    </div>
  );
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default Folder;
