import React, { useState, useEffect } from "react";
import axios from "axios";
import folderImage from "../../images/macFolder.png";
import fileIcon from "../../images/fileIcon.png";
import videoImage from "../../images/videoImage.png";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { FileUploader } from "react-drag-drop-files";
import { FaCloudUploadAlt, FaFileAlt } from "react-icons/fa";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FolderIcon from "@mui/icons-material/Folder";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Preview } from "@mui/icons-material";
import { Button, TextField, Snackbar } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import FileUploadIcon from "@mui/icons-material/FileUpload";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};
const Input = styled("input")({
  display: "none",
});
const ITEM_HEIGHT = 48;
const Storage = ({}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFormat, setCurrentFormat] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [showRename, setShowRename] = useState(false);
  const [currentFolder, setCurrentFolder] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [createNewFolderName, setCreateNewFolderName] = useState("");
  const [file, setFile] = useState(null);
  const [routeArray, setRouteArray] = useState([]);
  const [listFolderAndFile, setListFolderAndFile] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorAddEl, setAnchorAddEl] = React.useState(null);
  const [modalOpen, setOpenModal] = React.useState(false);
  const [isLoader, setIsLoader] = React.useState(false);
  const [modalFileOpen, setOpenModalFile] = React.useState(false);
  const [modalFolderOpen, setOpenModalFolder] = React.useState(false);
  const [modalThumbnailFileOpen, setOpenModalThumbnailFile] =
    React.useState(false);
  const [showDownload, setShowDownload] = React.useState(true);
  const [showThumbnail, setShowThumbnail] = React.useState(true);
  const fileTypes = ["JPEG", "PNG", "GIF", "PDF", "DOCX", "TXT"];

  // const handleChange = (file) => {
  //   setFile(file);
  // };

  // const handleFolderChange = (event) => {
  //   const files = event.target.files;
  //   const filesArray = Array.from(files);
  //   setSelectedFiles(filesArray);
  // };

  const handleFolderChange = (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);
    setSelectedFiles(filesArray);
  };
  const uploadBatch = async (filesBatch) => {
    const formData = new FormData();
    filesBatch.forEach((file) => {
      formData.append("files", file, routeArray.join("/")?.replace(/\/{2,}/g, "/") + file.webkitRelativePath || routeArray.join("/")?.replace(/\/{2,}/g, "/") + file.name);
    });
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/uploadFolder`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Batch uploaded successfully");
    } catch (error) {
      console.error("Error uploading batch:", error);
      throw error;
    }
  };

  const handleUploadFolder = async () => {
    setIsLoader(true);
    const filesArray = Array.from(selectedFiles);
    const totalFiles = filesArray.length;
    let startIndex = 0;

    try {
      while (startIndex < totalFiles) {
        const endIndex = Math.min(startIndex + 1, totalFiles);
        const filesBatch = filesArray.slice(startIndex, endIndex);
        await uploadBatch(filesBatch);
        startIndex = endIndex;
      }
      alert("All files uploaded successfully!");
    } catch (error) {
      alert("Failed to upload some files.");
      console.log(error);
    } finally {
      setIsLoader(false);
      setOpenModalFolder(false);
      listAll();
    }

    // setIsLoader(true);
    // const formData = new FormData();
    // selectedFiles.forEach((file) => {
    //   formData.append("files", file, file.webkitRelativePath);
    // });

    // try {
    //   const response = await axios
    //     .post(`${process.env.REACT_APP_API_URL}/uploadFolder`, formData, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     })
    //     .then((res) => {
    //       listAll();
    //       setSelectedFiles([]);
    //       setOpenModalFolder(false);
    //       setIsLoader(false);
    //     });
    // } catch (error) {
    //   console.error("Error uploading folder:", error);
    //   alert("Failed to upload folder.");
    // }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setCreateNewFolderName("");
  };
  const handleModalRenameClose = () => {
    setShowRename(false);
    setCreateNewFolderName("");
  };
  const handleModalFileClose = () => {
    setFile(null);
    setOpenModalFile(false);
    setCreateNewFolderName("");
  };

  const handleModalFolderClose = () => {
    setSelectedFiles([]);
    setOpenModalFolder(false);
    setCreateNewFolderName("");
  };
  const handleModalThumbnailFileClose = () => {
    setOpenModalThumbnailFile(false);
    setCreateNewFolderName("");
  };
  const open = Boolean(anchorEl);
  const openAdd = Boolean(anchorAddEl);
  const handleClick = (event, data) => {
    setCurrentFolder(data);
    if (data?.type === "folder") {
      setShowThumbnail(true);
      setShowDownload(true);
      setOptions((prev) => ["Rename", "Share", "Delete"]);
    } else if (data?.type === "video") {
      setShowThumbnail(false);
      setShowDownload(false);
      setOptions((prev) => [
        "Delete",
        "Share",
        "Rename",
        "Download",
        "Add Thumbnail",
      ]);
    } else if (data?.type === "document") {
      setShowThumbnail(true);
      setShowDownload(false);
      setOptions((prev) => ["Delete", "Share", "Rename", "Download"]);
    } else if (data?.type === "image") {
      setShowThumbnail(true);
      setShowDownload(false);
      setOptions((prev) => ["Delete", "Share", "Rename", "Download"]);
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const handleAddClick = (event) => {
    setAnchorAddEl(event.currentTarget);
  };
  const handleAddClose = (e) => {
    console.log(e?.currentTarget?.getAttribute("value"));
    if (e?.currentTarget?.getAttribute("value") === "Add Folder") {
      setOpenModal(true);
    } else if (e?.currentTarget?.getAttribute("value") === "Upload File") {
      setOpenModalFile(true);
    } else if (e?.currentTarget?.getAttribute("value") === "Upload Folder") {
      setOpenModalFolder(true);
    }
    setAnchorAddEl(null);
  };

  const handleFileChange = (e) => {
    setFile(e);
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/createFile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/openFolder`
      );

      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const createFolder = async () => {
    try {
      let val = listFolderAndFile.find(
        (x) =>
          x.type == "folder" && x.name?.replace("/", "") === createNewFolderName
      );
      if (val == null) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/createFolder`,
          { folderName: createNewFolderName, parentFolder: routeArray }
        );
        setCreateNewFolderName("");
        setShowAlert(false);
        listAll();
        setOpenModal(false);
      } else setShowAlert(true);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const uploadFile = async () => {
    try {
      let temp = file.name;
      let temp1 = temp.split(".");
      temp1.splice(temp1.length - 1, 1);
      let temp2 = temp1.join(",");
      console.log(temp2);
      let payloadFile = file.name;
      setIsLoader(true);
      const formData = new FormData();
      formData.append("file", file);
      console.log(file);
      formData.append("parentFolder", routeArray);
      formData.append("payloadFile", payloadFile);
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/createFile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setOpenModalFile(false);
          listAll();
          setFile(null);
        });
    } catch (error) {
      setFile(null);
      console.error("Error uploading file:", error);
    }
  };

  const uploadThumnialFile = async () => {
    try {
      setOpenModalThumbnailFile(true);
      setIsLoader(true);
      const formData = new FormData();
      // console.log(fileType);
      formData.append("file", file);
      formData.append("parentFolder", routeArray);
      formData.append("payloadFile", currentFolder.name + "-thumb-");
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/uploadThumbnail`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setOpenModalFile(false);
          listAll();
          setFile(null);
          setOpenModalThumbnailFile(false);
        });
    } catch (error) {
      setFile(null);
      console.error("Error uploading file:", error);
    }
  };

  const renameFolder = async () => {
    try {
      setIsLoader(true);
      let val = listFolderAndFile.find(
        (x) =>
          x.type == "folder" && x.name?.replace("/", "") === createNewFolderName
      );
      if (val == null) {
        const response = await axios
          .put(`${process.env.REACT_APP_API_URL}/renameFolder`, {
            oldFolderName: currentFolder,
            newFolderName:
              currentFolder.type == "folder"
                ? createNewFolderName
                : createNewFolderName + "." + currentFormat,
            path: routeArray,
          })
          .then((res) => {
            listAll();
            handleClose();
            setIsLoader(false);
          });
        setCreateNewFolderName("");
        setShowAlert(false);
        listAll();
        setShowRename(false);
      } else setShowAlert(true);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const deleteFolder = async () => {
    try {
      const response = await axios
        .put(`${process.env.REACT_APP_API_URL}/deleteFolder`, {
          folderName: currentFolder,
          path: routeArray,
        })
        .then((response) => {
          setAnchorEl(null);
          listAll();
        });

      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const downloadFile = async () => {
    try {
      console.log(currentFolder);
      if (currentFolder?.type == "image") {
        // const response = await fetch(currentFolder.downloadUrl);
        // const blob = await response.blob();
        // const url = window.URL.createObjectURL(blob);
        // const link = document.createElement("a");
        // link.href = url;
        // link.download = currentFolder.name;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(url);
        const link = document.createElement("a");
        link.href = currentFolder.downloadUrl;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
      }
      else if(currentFolder?.type == "video") 
      {
        const a = document.createElement("a");
        a.href = currentFolder.downloadUrl;
        a.download = currentFolder.name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
      else {
        const response = await fetch(currentFolder.downloadUrl);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = currentFolder.name;
        document.body.appendChild(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(blobUrl);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const addThumbnail = async () => {
    setOpenModalThumbnailFile(true);
  };

  const renameFile = async () => {
    try {
      const result = currentFolder.name.replace(/\/$/, "");
      let temp = result;
      let temp1 = temp.split(".");
      temp1.splice(temp1.length - 1, 1);
      let temp2 = temp1.join(",");
      console.log(temp2);
      let payloadFile = temp2;
      if (currentFolder.type != "folder")
        setCurrentFormat(temp.split(".")[temp.split(".").length - 1]);
      else setCurrentFormat("");
      setCreateNewFolderName(payloadFile);
      setShowRename(true);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const shareFolder = async () => {
    try {
      console.log(routeArray.join("/")?.replace(/\/{2,}/g, "/"));
      console.log(currentFolder);
      let utfUrl =
        currentFolder.type == "folder"
          ? window.location.origin +
            "/folder?folderUrl=" +
            btoa(
              routeArray.join("/")?.replace(/\/{2,}/g, "/") +
                encodeURIComponent(currentFolder?.name) +
                "/"
            )
          : currentFolder.type == "document" || currentFolder.type == "image"
          ? window.location.origin +
          "/" + currentFolder.type +"?documentUrl=" +
          btoa(
            routeArray.join("/")?.replace(/\/{2,}/g, "/") +
              encodeURIComponent(currentFolder?.name)
          )
          : window.location.origin +
            "/video?videoUrl=" +
            btoa(
              routeArray.join("/")?.replace(/\/{2,}/g, "/") +
                encodeURIComponent(currentFolder?.name)
            );

      console.log(utfUrl);

      const plainTextContent = utfUrl;

      navigator.clipboard
        .write([
          new ClipboardItem({
            "text/plain": new Blob([plainTextContent], { type: "text/plain" }),
          }),
        ])
        .then(() => {
          console.log("Email info copied to clipboard!");
          setSnackbarOpen(true);
        })
        .catch((err) => {
          console.error("Failed to copy email info: ", err);
        });
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return; // Prevents the snackbar from closing when the user clicks outside of it
    }
    setSnackbarOpen(false); // Close the Snackbar
  };

  const listFolder = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/openFolder`,
        { folderName: "syed" }
      );

      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const listAll = async () => {
    try {
      setIsLoader(true);
      console.log(routeArray);
      console.log(routeArray.join("/"));
      let routeTemp = routeArray?.join("/")?.replace("//", "/");
      if (!routeTemp?.endsWith("/") && routeTemp != "") {
        routeTemp = routeTemp + "/";
      }
      const response = await axios
        .post(`${process.env.REACT_APP_API_URL}/listAll`, {
          folderPath: routeTemp,
        })
        .then(async (res) => {
          for (let item of res.data?.items) {
            if (item.type == "video") {
              const blobUrl = await createBlobUrl(item.downloadUrl);
              console.log(blobUrl);
              break;
            }
          }
          setIsLoader(false);
          setListFolderAndFile([]);
          setListFolderAndFile(res?.data?.items || []);
          console.log("File uploaded successfully:", res.data);
        });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    listAll();
  }, [routeArray]);

  const createBlobUrl = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      console.log(blob);
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error("Error creating Blob URL:", error);
      return null;
    }
  };

  return (
    <div>
      <div className="flex" style={{ flexWrap: "wrap" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            {routeArray.length > 0 && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <button onClick={() => setRouteArray([])}>
                  <FolderIcon style={{ color: "#8f8f8f", fontSize: "40px" }} />
                </button>
              </div>
            )}
            {routeArray?.map((x, index) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <KeyboardDoubleArrowRightIcon
                  style={{ color: "#8f8f8f", fontSize: "30px" }}
                />
                <button
                  style={{ fontSize: "20px" }}
                  onClick={(e) => {
                    setRouteArray((prevArray) => {
                      const updatedArray = [...prevArray];
                      updatedArray?.splice(index + 1, updatedArray.length);
                      return updatedArray;
                    });
                  }}
                >
                  {x?.replace(/\//g, "")}
                </button>
              </div>
            ))}
          </div>
          <div style={{ padding: "10px" }}>
            {/* <Button
              variant="contained"
              id="basic-button"
              aria-controls={openAdd ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openAdd ? "true" : undefined}
              onClick={handleAddClick}
              style={{ marginTop: "10px" }}
            >
              Add
            </Button> */}
            <Button
              value="Upload Folder"
              onClick={handleAddClose}
              variant="contained"
            >
              Upload Folder
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <Button
              value="Add Folder"
              onClick={handleAddClose}
              variant="contained"
            >
              Add Folder
            </Button>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              value="Upload File"
              onClick={handleAddClose}
              variant="contained"
            >
              Upload File
            </Button>
            {/* <Menu
              id="basic-menu"
              anchorEl={anchorAddEl}
              open={openAdd}
              onClose={handleAddClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              style={{ right: 0 }}
            >
              <MenuItem onClick={handleAddClose} value="Add Folder">
                Add Folder
              </MenuItem>
              <MenuItem onClick={handleAddClose} value="Upload File">
                Upload File
              </MenuItem>
            </Menu> */}
          </div>
        </div>
        {listFolderAndFile &&
          listFolderAndFile?.length > 0 &&
          listFolderAndFile.map((x) => (
            <div>
              {x?.type == "folder" && x?.name != "" && (
                <div
                  className="flex justify-center items-center flex-col"
                  style={{
                    margin: "10px",
                    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => {
                        handleClick(e, x);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      marginBottom: "-7px",
                    }}
                  >
                    <button
                      folderType={x?.type}
                      folderName={x?.name}
                      onClick={(e) => {
                        if (
                          e?.currentTarget?.getAttribute("folderType") ==
                          "folder"
                        ) {
                          setRouteArray((prevArray) => [
                            ...prevArray,
                            e?.currentTarget?.getAttribute("folderName") + "/",
                          ]);
                          console.log(routeArray);
                        }
                      }}
                    >
                      <img
                        src={folderImage}
                        style={{ height: "100px", width: "130px" }}
                      />
                    </button>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {x?.name?.replace("/", "")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}

        {listFolderAndFile &&
          listFolderAndFile?.length > 0 &&
          listFolderAndFile.map((x) => (
            <div>
              {x?.type == "video" && x?.name != "" && (
                <div
                  className="flex justify-center items-center flex-col"
                  style={{
                    margin: "10px",
                    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => {
                        handleClick(e, x);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                  <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                    <video
                      controls
                      style={{ height: "100px", width: "130px" }}
                      poster={x?.thumbNail}
                    >
                      <source src={x?.downloadUrl} />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {x?.name?.replace("/", "")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}

        {listFolderAndFile &&
          listFolderAndFile?.length > 0 &&
          listFolderAndFile.map((x) => (
            <div>
              {x?.type == "image" &&
                x?.name != "" &&
                !x?.name?.includes("-thumb-") && (
                  <div
                    className="flex justify-center items-center flex-col"
                    style={{
                      margin: "10px",
                      boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                          handleClick(e, x);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </div>
                    <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                      <img
                        src={x?.downloadUrl}
                        style={{ height: "100px", width: "130px" }}
                      />
                    </div>
                    <div>
                      <div
                        style={{
                          width: "100px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {x?.name?.replace("/", "")}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          ))}

        {listFolderAndFile &&
          listFolderAndFile?.length > 0 &&
          listFolderAndFile.map((x) => (
            <div>
              {(x?.type == "document" || x?.type == "unknown") && x?.name != "" && (
                <div
                  className="flex justify-center items-center flex-col"
                  style={{
                    margin: "10px",
                    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => {
                        handleClick(e, x);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                  <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                    <img
                      src={fileIcon}
                      style={{ height: "100px", width: "130px" }}
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {x?.name?.replace("/", "")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <>
            {
              <MenuItem
                key={option}
                value={option}
                onClick={(e) => {
                  setAnchorEl(null);
                  console.log(e?.currentTarget?.getAttribute("value"));
                  let key = e?.currentTarget?.getAttribute("value");
                  if (key === "Delete") {
                    deleteFolder();
                  } else if (key === "Share") {
                    shareFolder();
                  } else if (key === "Rename") {
                    renameFile();
                  } else if (key === "Download") {
                    downloadFile();
                  } else if (key === "Add Thumbnail") {
                    addThumbnail();
                  }
                }}
              >
                {option}
              </MenuItem>
            }
          </>
        ))}
      </Menu>

      <Snackbar
        open={snackbarOpen}
        style={{ right: 0, top: "65px" }}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleCloseSnackbar}
        message="Copied to clipboard!"
      />
      <Modal
        open={isLoader}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Modal>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {showAlert && <Alert severity="error">Folder Alreay Exist..</Alert>}
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create Folder
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TextField
              label="Folder Name"
              fullWidth
              value={createNewFolderName}
              margin="normal"
              name="folderName"
              onChange={(e) => {
                setCreateNewFolderName((prev) => e.target.value);
              }}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                fullWidth
                sx={{ mt: 2 }}
                onClick={createFolder}
                style={{ width: "100px" }}
                disabled={createNewFolderName?.length < 3}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => {
                  setOpenModal(false);
                }}
                style={{ width: "100px" }}
              >
                Cancel
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={modalFileOpen}
        onClose={handleModalFileClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Upload File
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <label htmlFor="contained-button-file">
                  <Input
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Button variant="contained" component="span">
                    Upload Video <FileUploadIcon />
                  </Button>
                </label>
              </div> */}
              <div>
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    border: "2px dashed #ccc",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FileUploader
                    handleChange={handleFileChange}
                    name="file"
                    children={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <FaCloudUploadAlt size={50} color="#007bff" />
                        <p>
                          Drag and drop your file here, or click to select a
                          file
                        </p>
                      </div>
                    }
                  />
                  {file && (
                    <div style={{ marginTop: "20px" }}>
                      <p>File uploaded:</p>
                      <p style={{ display: "flex" }}>
                        <FaFileAlt size={20} />
                        {file.name}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ mt: 2 }}
                  onClick={uploadFile}
                  style={{ width: "100px" }}
                  disabled={file == null ? true : false}
                >
                  Upload
                </Button>{" "}
                &nbsp;&nbsp;
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ mt: 2 }}
                  onClick={() => {
                    setOpenModalFile(false);
                  }}
                  style={{ width: "100px" }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={modalThumbnailFileOpen}
        onClose={handleModalThumbnailFileClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Upload Thumbnail
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <label htmlFor="contained-button-file">
                  <Input
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleFileChange}
                  />
                  <Button variant="contained" component="span">
                    Upload Image <FileUploadIcon />
                  </Button>
                </label>
              </div> */}
              <div>
                <div
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    border: "2px dashed #ccc",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <FileUploader
                    handleChange={handleFileChange}
                    name="file"
                    children={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <FaCloudUploadAlt size={50} color="#007bff" />
                        <p>
                          Drag and drop your file here, or click to select a
                          file
                        </p>
                      </div>
                    }
                  />
                  {file && (
                    <div style={{ marginTop: "20px" }}>
                      <p>File uploaded:</p>
                      <p style={{ display: "flex" }}>
                        <FaFileAlt size={20} />
                        {file.name}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ mt: 2 }}
                  onClick={uploadThumnialFile}
                  style={{ width: "100px" }}
                  disabled={file == null ? true : false}
                >
                  Submit
                </Button>{" "}
                &nbsp;&nbsp;
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ mt: 2 }}
                  onClick={() => {
                    setOpenModalThumbnailFile(false);
                  }}
                  style={{ width: "100px" }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={showRename}
        onClose={handleModalRenameClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {showAlert && <Alert severity="error">Folder Alreay Exist..</Alert>}
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Rename {currentFolder?.type == "folder" ? "Folder" : "File"}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <TextField
              label="Folder Name"
              fullWidth
              value={createNewFolderName}
              margin="normal"
              name="folderName"
              onChange={(e) => {
                setCreateNewFolderName((prev) => e.target.value);
              }}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="contained"
                fullWidth
                sx={{ mt: 2 }}
                onClick={renameFolder}
                style={{ width: "100px" }}
                disabled={createNewFolderName?.length < 3}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => {
                  setShowRename(false);
                }}
                style={{ width: "100px" }}
              >
                Cancel
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={modalFolderOpen}
        onClose={handleModalFolderClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Upload Folder
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  border: "2px dashed #007bff",
                  padding: "20px",
                  cursor: "pointer",
                  borderRadius: "4px",
                }}
              >
                <input
                  type="file"
                  id="folder-upload"
                  webkitdirectory="true"
                  multiple
                  onChange={handleFolderChange}
                  style={{ display: "none" }}
                />
                <label htmlFor="folder-upload">
                  <Button variant="contained" color="primary" component="span">
                    <FaCloudUploadAlt size={50} color="white" />
                  </Button>
                </label>
                <Typography variant="body2" sx={{ marginTop: "10px" }}>
                  Drag and drop your folder here, or click to select a folder
                </Typography>
              </Box>
              <Button
                variant="contained"
                fullWidth
                sx={{ mt: 2 }}
                onClick={handleUploadFolder}
                disabled={selectedFiles.length === 0}
              >
                Upload Folder
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
      {/* <button onClick={listAll}>List All</button>
      <br />
      <br />
      <br />
      <br />
      <button onClick={createFolder}>Create folder</button>
      <br />
      <button onClick={renameFolder}>Edit folder</button>
      <br />
      <button onClick={deleteFolder}>Delete folder</button>
      <br />
      <button onClick={listFolder}>List folder</button>
      <br />
      <br />
      <br />
      <br />
      <br />
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload file</button>
      <button onClick={handleList}>List Document</button>
      <br /> */}
    </div>
  );
};
export default Storage;
